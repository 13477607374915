.emr-app-main{
  min-height: 101vh;
  position: relative;
}

.emr-app-main, .emr-clerking-tab-data{
  animation: 200ms ease-in 0ms 1 fadeIn;
}

.emr-app-main > .emr-icon-bg{
  position: fixed;
  bottom: 16px; right: 0; left: 0;
  width: 48px;
  height: 48px;
  box-shadow: var(--emr-low-shadow);
  margin: auto;
  /* border: 1px solid var(--emr-secondary-colour); */
  transition: box-shadow 300ms ease-in, bottom 300ms ease-in;
}

.emr-app-main > .emr-icon-bg:hover{
  box-shadow: var(--emr-high-shadow);
  bottom: 50px;
}

.emr-app-main .emr-icon-bg:active{
  box-shadow: var(--emr-active-shadow);
}

table{
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

table i.emr-center-icon{
  line-height: 1.5;
}

thead{
  font-size: 8.3pt;
  font-weight: bold;
  color: white;
  background-color: var(--emr-secondary-colour);
}

tbody{
  border-collapse: separate;
  border-spacing: 0 8px;
  color: var(--emr-darker-grey-colour);
}

tbody tr{
  background-color: white;
  transition: background-color 200ms ease-in;
}

tr{
  border-radius: var(--emr-round-corners);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.17);
}

tr.emr-loading{
  background-color: var(--emr-background-colour);
}

p.emr-loading{
  position: relative;
  color: transparent;
  /* background-color: var(--emr-lighter-grey-colour); */
  border-radius: var(--emr-round-corners);
  overflow: hidden;
}

p.emr-loading::before{
  content: "";
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, var(--emr-lighter-grey-colour), 
    var(--emr-grey-colour), var(--emr-lighter-grey-colour));
  border-radius: var(--emr-round-corners);
  animation: 1s linear infinite loadingTable;
}

tbody tr:hover{
  background-color: var(--emr-lighter-grey-colour);
  opacity: 0.8;
  cursor: pointer;
}

tbody tr:active{
  background-color: var(--emr-light-colour);
}

td:first-child, th:first-child{
  border-radius: var(--emr-round-corners) 0 0 var(--emr-round-corners);
}

td:last-child, th:last-child{
  border-radius: 0 var(--emr-round-corners) var(--emr-round-corners) 0;
}

td, th{
  text-align: center;
  vertical-align: middle;
  padding: 18px;
  margin: 8px 0;
}

td{
  font-size: 9pt;
  font-weight: 600;
}

th{
  cursor: pointer;
}

.emr-headers{
  padding-left: 8px;
}

.emr-coming-soon{
  width: 100%;
  height: 128px;
  position: relative;
}

.emr-coming-soon-text{
  width: 100%;
  height: 16px;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: auto;
  font-size: 8pt;
  color: var(--emr-grey-colour);
  font-style: italic;
  text-align: center;
}

.emr-selectable-items-group{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.emr-selectable-item{
  min-width: 20%;
  padding: 16px;
  margin-right: 24px;
  margin-bottom: 12px;
  color: var(--emr-darker-grey-colour);
  border: 3px solid var(--emr-primary-light-colour);
  border-radius: 32px;
}

.emr-selectable-item:hover{
  border-color: var(--emr-secondary-colour);
  background-color: var(--emr-light-colour);
}

.emr-selectable-item:active, .emr-selectable-item.selected{
  color: white;
  border-color: var(--emr-accent-colour);
  background-color: var(--emr-secondary-colour);
  box-shadow: var(--emr-default-shadow);
}

.emr-selectable-item > p{
  width: 100%;
  line-height: inherit;
  font-size: 9.5pt;
  font-weight: 700;
  text-align: center;
  color: inherit;
  background-color: transparent;
}

.container-fluid.emr-dialog-backdrop.show{
  display: block;
  animation: 200ms ease-in 0ms 1 fadeIn;
}

.container-fluid.emr-dialog-backdrop{
  display: none;
  position: fixed;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: auto;
}

.emr-dialog{
  border-radius: var(--emr-round-corners);
  box-shadow: var(--emr-default-shadow);
  /* background: url('https://firebasestorage.googleapis.com/v0/b/emrapp-6b53d.appspot.com/o/abstract_connections_banner_design_2203.png?alt=media&token=dc90182d-c0ee-49b6-b317-6c240309bd9e'); */
  background-color: white;
  /* background-color: var(--emr-accent-light-colour); */
  background-size: cover;
  /* background-position: 50% 50%; */
  background-blend-mode: luminosity;
  animation: 200ms ease-in 0ms 1 slideUp;
  max-height: 100%;
}

.emr-dialog.emr-select-dialog{
  position: relative;
  height: 90vh;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: 5% auto;
  overflow: auto;
}

.emr-select-dialog > .emr-icon-bg{
  position: absolute;
  top: 8px; right: 8px;
}

.emr-dialog > *{
  padding: var(--emr-default-padding);
}

.emr-dialog .row{
  height: auto;
}

.emr-dialog-patients {
  border-top-right-radius: var(--emr-round-corners);
  border-bottom-right-radius: var(--emr-round-corners);
}

.emr-patients-no-duplicate {
  overflow-y: auto;
  max-height: 75vh;
}

p.emr-form-item-description{
  font-size: 11pt;
}

li.emr-form-item-description{
  font-size: 10pt;
  font-weight: bold;
  color: var(--emr-darker-grey-colour);
}

.emr-form-item-score > input{
  min-height: 64px;
  font-size: 15pt;
  font-weight: bold;
  text-align: center;
}

.emr-dialog-details{
  width: 100%;
  padding: 32px var(--emr-default-padding);
  color: var(--emr-darker-grey-colour);
  font-size: 16px;
  /* display: inline-block;
  text-align: center; */
  background-color: white;
  border-top: 1px solid var(--emr-lighter-grey-colour);
  border-bottom: 1px solid var(--emr-lighter-grey-colour);
}

.emr-dialog-input {
  height: 100%;
}

.emr-dialog-buttons.container-fluid {
  height: auto;
}

button.emr-dialog-cancel-button{
  /* background-color: var(--emr-light-colour); */
  background-color: transparent;
  border: 1px solid var(--emr-primary-colour);
  color: var(--emr-secondary-colour);
}

button.emr-dialog-cancel-button:hover{
  color: white;
  border-color: transparent;
}

button.emr-dialog-cancel-button, button.emr-dialog-ok-button {
  transition: background-color 500ms ease-in;
}

button.emr-dialog-ok-button:hover, button.emr-dialog-cancel-button:hover{
  background-color: var(--emr-primary-colour);
}

.emr-dialog-title-text{
  color: var(--emr-secondary-colour);
  font-weight: 700;
  font-size: 14pt;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.emr-patient-summary p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}

.emr-upload-progress{
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: transform 250ms ease-in;
}

@keyframes fadeIn{
  from{
    opacity: 0.5;
    transform: scale(0.99);
  }

  to{
    opacity: 1;
    transform: none;
  }
}

@keyframes loadingTable{
  from{
    transform: translate(-100%);
  }

  to{
    transform: translate(100%);
  }
}

@media only screen and (min-width: 992px){
  .emr-app-main > .emr-icon-bg{
    bottom: 24px; right: 24px;
  }
}

@media only screen and (min-width: 1224px){
  .emr-app-main > .emr-icon-bg{
    bottom: 24px; right: 25vw;
  }
}
